import React from "react";

const Rock = require("../../rock.png");
const Paper = require("../../paper.png");
const Scissor = require("../../scissors.png");

const Weapon = ({ weapon, isSelected, onClick }) => {
  const getImage = () => {
    switch (weapon) {
      case "Rock":
        return Rock;
      case "Paper":
        return Paper;
      case "Scissor":
        return Scissor;
    }
  };

  return (
    <div
      style={{
        ...styles.playButton,
        backgroundImage: `url(${getImage()})`,
        borderColor: isSelected ? "green" : "white"
      }}
      onClick={onClick}
    />
  );
};

const styles = {
  playButton: {
    height: 70,
    width: 50,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#eceef0",
    cursor: "pointer",
    border: "thin solid",
    borderColor: "red",
    borderRadius: 5,
    overflow: "hidden",
    margin: 5
  }
};

export default Weapon;
