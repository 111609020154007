import React, { useEffect, useState } from "react";
import _ from "lodash";
import Game from "../Game";

const Games = ({ contract, web3, addToLog }) => {
  const [games, setGames] = useState([]);

  const fetchPendingGames = () => {
    try {
      contract.methods.getAvailableMatches().call(function(err, res) {
        if (!err) {
          setGames(res);
        } else {
          console.log(err);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!contract) return;

    fetchPendingGames();
  }, [contract]);

  return (
    <div>
      <div className="container">
        {_.chunk(games, 3).map((gameChunk, index) => (
          <div key={`row-${index}`} className="row py-2">
            {gameChunk.map(game => {
              return (
                <div className="col-sm" key={game.id}>
                  <Game fetchPendingGames={fetchPendingGames} game={game} />
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Games;
