import React from "react";
import { connect } from "react-redux";

const TokenStatus = ({ contract, logs }) => {
  return (
    <div className="status-box">
      {logs.reverse().map((log, index) => (
        <p key={`${index}-${log.title}`}>
          {log.title} <br /> {log.subtitle}
        </p>
      ))}

      <p>Contract: {contract ? contract._address : "Loading"}</p>
    </div>
  );
};

const mapStateToProps = state => {
  const { logs } = state.reducers;
  return { logs };
};

export default connect(mapStateToProps)(TokenStatus);
