import React, { useEffect, useState } from "react";
import { connect, Provider } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GSwapContract from "../../contracts/Token.json";
import Disconnected from "../../containers/Disconnected";
import getWeb3 from "../../getWeb3";
import "../../App.css";
import TokenStatus from "../TokenStatus";
import Games from "../Games";
import { addToLog, setContract } from "../../actions";
import CreateGame from "../CreateGame";
import store from "../../store";

const MySwal = withReactContent(Swal);

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

const App = ({ addToLog, contract, setContract }) => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    initAsync();
  }, []);

  useEffect(() => {
    if (!account || !contract) return;

    contract.methods.getPlayersGames(account).call(function(err, res) {
      console.log("GAMEZZZ", err, res);
    });

    contract.methods.balanceOf(account).call(function(err, res) {
      if (!err) {
        addToLog({ title: "Your account balance:", subtitle: res / 10 ** 18 });
      } else {
        console.log(err);
      }
    });

    contract.methods.balanceOf(contract._address).call(function(err, res) {
      if (!err) {
        addToLog({
          title: "Escrow balance:",
          subtitle: parseInt(res, 10) / 10 ** 18
        });
      } else {
        console.log(err);
      }
    });

    contract.methods.totalSupply().call(function(err, res) {
      if (!err) {
        addToLog({
          title: "Total Grickels in circulation:",
          subtitle: parseInt(res, 10) / 10 ** 18
        });
      } else {
        console.log(err);
      }
    });
  }, [account, contract]);

  const initAsync = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      const accounts = await web3.eth.getAccounts();

      // Get the contract instance.
      const networkId = await web3.eth.net.getId();

      const deployedNetwork = GSwapContract.networks[networkId];

      const instance = new web3.eth.Contract(
        GSwapContract.abi,
        deployedNetwork && deployedNetwork.address,
        { from: accounts[0] }
      );

      setWeb3(web3);

      setContract(instance);

      setAccount(accounts[0]);
    } catch (error) {
      // Catch any errors for any of the above operations.
      alert(
        `Failed to load web3, accounts, or contract. Check console for details.`
      );
      console.error(error);
    }
  };

  const handleCreateGame = () => {
    MySwal.fire({
      title: "Create New Game",
      icon: "question",
      cancelButtonText: "Nevermind",
      showConfirmButton: false,
      html: (
        <Provider store={store}>
          <CreateGame
            onGameCreationStarted={() => {
              MySwal.close();
            }}
          />
        </Provider>
      )
    });

    // Toast.fire({
    //   icon: "success",
    //   title: "Signed in successfully"
    // });
  };

  if (!web3) {
    return <Disconnected onConnect={initAsync} />;
  }

  return (
    <div className="App">
      <TokenStatus contract={contract} />

      <img
        onClick={handleCreateGame}
        alt="EatSleepCryptoRepeat"
        width={175}
        src={require("../../rock_paper_dribbbl.png")}
      />

      <h3>GRICKEL</h3>
      <p>
        Token Address: <strong>{contract ? contract._address : ""}</strong>
      </p>
      <p>
        Rock Paper Scissor rules. Winner takes GRICKLES. Tie refunds GRICKLES
        back to game creator.
      </p>
      <p>Grickles are the coins used to wager on the GRICKLE protocol.</p>

      <br />

      <button onClick={handleCreateGame} className="btn btn-outline-success">
        CREATE NEW GAME
      </button>

      <br />
      <br />
      <br />

      <h1>AVAILABLE GAMES</h1>

      <Games web3={web3} contract={contract} />
    </div>
  );
};

const mapStateToProps = state => {
  const { contract } = state.reducers;
  return { contract };
};

export default connect(
  mapStateToProps,
  { addToLog, setContract }
)(App);
