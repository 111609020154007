import React from "react";

const Disconnected = ({ onConnect }) => {
  return <div style={{ height: '100vh', width: '100vw', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
    <video style={{ height: 250, marginTop: -200 }} className="lrg-16x12 lazyloaded" preload="auto" muted loop playsInline autoPlay
           src="https://cdn.dribbble.com/users/5976/screenshots/15212630/media/845ae777e861289324659b939553f4a3.mp4">
    </video>
    <h1>GRICKLE</h1>
    <br />
    <button className="btn btn-success border outline btn-lg" onClick={onConnect}>Connect to app</button>
  </div>
}

export default Disconnected;
