import { combineReducers } from "redux";

export const ADD_LOG = "ADD_LOG";
export const SET_CONTRACT = "SET_CONTRACT";

const initialState = {
  logs: [],
  contract: null
};

const reducers = function(state = initialState, action) {
  switch (action.type) {
    case ADD_LOG: {
      return {
        ...state,
        logs: [...state.logs, action.payload]
      };
    }

    case SET_CONTRACT:
      return {
        ...state,
        contract: action.contract
      };
    default:
      return state;
  }
};

export default combineReducers({ reducers });
