import { ADD_LOG, SET_CONTRACT } from "./reducers";

export const addToLog = payload => ({
  type: ADD_LOG,
  payload
});

export const setContract = contract => ({
  type: SET_CONTRACT,
  contract
});
