/* global BigInt */
import React, { useState } from "react";
import { connect } from "react-redux";
import { addToLog } from "../../actions";
import Weapon from "../../components/Weapon";
import WeaponModel from "../../models/Weapon";

const CreateGame = ({
  contract,
  onCreateSuccess,
  addToLog,
  onGameCreationStarted
}) => {
  const [amount, setAmount] = useState(0);
  const [selectedWeapon, setSelectedWeapon] = useState(null);

  const handleCreateGame = e => {
    e.preventDefault();

    const _amount = BigInt(parseInt(amount, 10) * 10 ** 18) + "";

    try {
      contract.methods
        .createGame(selectedWeapon, _amount)
        .send(function(err, res) {
          if (!err) {
            setSelectedWeapon(null);
            setAmount(0);
            addToLog({ title: "Pending Game Tx", subtitle: res });
            onGameCreationStarted();
          } else {
            console.log(err);
          }
        })
        .on("transactionHash", function(hash) {
          console.log("transactionHash: " + hash);
        })
        .on("confirmation", (a, receipt) => {
          onCreateSuccess();
          console.log(receipt);
          addToLog({
            title: "Game Created Successfully",
            subtitle: `Gas Used: ${receipt.gasUsed}, Block: ${
              receipt.blockNumber
            }`
          });
        })
        .on("error", console.error);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <form style={{ maxWidth: 300 }}>
        <div className="form-group">
          <label htmlFor="wager">Wager amount in GRICK</label>
          <input
            name="wager"
            type="number"
            value={amount}
            onChange={e => setAmount(e.target.value)}
            className="form-control"
            placeholder="Amount"
          />
        </div>
        <label>Select your weapon</label>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 15
          }}
        >
          {["Paper", "Rock", "Scissor"].map(weapon => (
            <Weapon
              key={weapon}
              weapon={weapon}
              isSelected={selectedWeapon === WeaponModel.Types[weapon]}
              onClick={() => {
                setSelectedWeapon(WeaponModel.Types[weapon]);
              }}
            />
          ))}
        </div>
        <div className="form-group">
          <button
            disabled={selectedWeapon === null || !amount}
            className="btn btn-outline-success"
            onClick={handleCreateGame}
          >
            Create Game
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => {
  const { contract } = state.reducers;
  return { contract };
};

export default connect(
  mapStateToProps,
  { addToLog }
)(CreateGame);
