import React, { useEffect, useState } from "react";
import moment from "moment";
import Weapon from "../../components/Weapon";
import { connect } from "react-redux";
import { addToLog } from "../../actions";

const Game = ({ game, contract, addToLog, fetchPendingGames }) => {
  const [selectedWeapon, setSelectedWeapon] = useState(null);
  const [result, setResult] = useState(null);
  const [winner, setWinner] = useState(null);
  const [tieGame, setTieGame] = useState(null);

  useEffect(() => {
    if (!result) return;

    const fighterOneWeapon = parseInt(result.fighterOneWeapon, 10);

    switch (fighterOneWeapon) {
      case 0:
        if (selectedWeapon === "Rock") setTieGame(true);
        if (selectedWeapon === "Paper") setWinner(true);
        if (selectedWeapon === "Scissor") setWinner(false);
        break;
      case 1:
        if (selectedWeapon === "Paper") setTieGame(true);
        if (selectedWeapon === "Rock") setWinner(false);
        if (selectedWeapon === "Scissor") setWinner(true);
        break;
      case 2:
        if (selectedWeapon === "Scissor") setTieGame(true);
        if (selectedWeapon === "Rock") setWinner(true);
        if (selectedWeapon === "Paper") setWinner(false);
        break;
    }
  }, [result]);

  const getIntWeapon = weapon => {
    switch (weapon || selectedWeapon) {
      case "Rock":
        return 0;
      case "Paper":
        return 1;
      case "Scissor":
        return 2;
    }
  };

  const handlePlayGame = () => {
    try {
      contract.methods
        .playGame(game.id, getIntWeapon(selectedWeapon))
        .send(function(err, res) {
          if (!err) {
            contract.methods.getMatch(game.id).call(function(err, receipt) {
              if (!err) {
                setResult(receipt);
                addToLog({
                  title: "Processing game results Tx",
                  subtitle: res
                });
              } else {
                console.log(err);
              }
            });
          } else {
            console.log(err);
          }
        })
        .on("confirmation", (a, receipt) => {
          fetchPendingGames();
          addToLog({
            title: "Game Results Processed Successfully",
            subtitle: `Gas Used: ${receipt.gasUsed}, Block: ${
              receipt.blockNumber
            }`
          });
        })
        .on("error", console.error);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="game-card">
      <h5>{moment.unix(parseInt(game.date, 10)).fromNow()}</h5>
      {winner === true && <h5 style={{ color: "green" }}>WINNER!</h5>}
      {winner === false && <h5 style={{ color: "red" }}>LOSER!</h5>}
      {tieGame && <h5>TIE GAME!</h5>}
      <div
        style={{ display: "flex", justifyContent: "center", paddingBottom: 15 }}
      >
        {["Paper", "Rock", "Scissor"].map(weapon => (
          <Weapon
            disabled={result}
            key={weapon}
            weapon={weapon}
            isSelected={selectedWeapon === weapon}
            onClick={() => {
              if (result) return;
              setSelectedWeapon(weapon);
            }}
          />
        ))}
      </div>
      <button
        onClick={handlePlayGame}
        disabled={!selectedWeapon || result}
        className="btn btn-outline-success"
      >
        {game.amount / 10 ** 18}
      </button>
    </div>
  );
};

const mapStateToProps = state => {
  const { contract } = state.reducers;
  return { contract };
};

export default connect(
  mapStateToProps,
  { addToLog }
)(Game);
